import styled from "@emotion/styled";
import {
  UmpireRed,
  BigWheelGreenTileBg,
  BigWheelPurpleTileBg
} from "assets/images";
import { numberInfo, statusColors } from "service";

export const NumberContainer = styled.div`
  position: relative;
  width: 88.6px;
  height: 88.6px;
  border-radius: 9px;
  background: url(${(props) => numberInfo[props.status].background});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Carnevalee Freakshow";
  &.number7 {
    .umpire {
        width: 139px;
        height: 129px;
        position: relative;
        top: -25px;
        bottom: 0;
        left: 16px;
        right: 0;
        margin: auto;
        margin-bottom: -7px;
        margin-left: -19px;
      }
    ${prop => prop.status === statusColors.white ? `
      background: url(${UmpireRed});
    ` : ``};
    background-size: 100% 100%;
    background-repeat: no-repeat;
    span {
      color: rgba(0, 0, 0, 0) !important;
    }
  }
  span {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-family: "Carnevalee Freakshow" !important;
    font-size: 65px !important;
  }
  .icon-check-num,
  .icon-free-num {
    position: absolute;
    opacity: 0.9;
  }
  .add-score-value {
    position: absolute;
  }

  .dust {
    position: absolute;
    width: 350px;
    height: 350px;
  }

  .hide-dust {
    display: none;
  }

  .dust-show {
    animation: dust-out 0.5s ease-out 1.5s forwards;
  }

  @keyframes dust-out {
    0% { opacity: 1; }
    100% { opacity: 0.5; }
  }

  .umpire {
    width: 139px;
    height: 129px;
    position: relative;
    top: -25px;
    bottom: 0;
    left: 16px;
    right: 0;
    margin: auto;
    margin-bottom: -1px;
    margin-left: -15px;
  }


  ${prop => prop.status === statusColors.blue ? `
    :not(.number7) {
      background: url(${BigWheelPurpleTileBg});
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  `: null}

  ${prop => prop.status === statusColors.green ? `
    :not(.number7) {
      background: url(${BigWheelGreenTileBg});
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  `: null}
`;